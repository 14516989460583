<template>
  <v-row class="customer-detail custom-border-top">
    <v-col md="12" sm="12">
      <v-layout>
        <v-flex md="3" class="mr-4">
          <v-text-field
            v-model.trim="search"
            append-icon="search"
            dense
            filled
            label="Search Site Information"
            solo
            flat
            color="cyan"
            :loading="searching"
            v-on:keyup.enter="getProperties()"
          >
          </v-text-field>
        </v-flex>
        <v-flex md="3">
          <v-btn
            v-on:click="getProperties()"
            class="mr-1"
            color="cyan white--text"
            >Search</v-btn
          ><v-btn
            v-on:click="(search = null), getProperties()"
            class="mr-1"
            style="max-width: 20px !important"
            color="red white--text"
            ><v-icon dark left>mdi-close</v-icon></v-btn
          >
          <v-btn v-on:click="createProperty()" color="cyan white--text"
            ><v-icon dark left>mdi-plus</v-icon> Create</v-btn
          >
        </v-flex>
        <v-flex md="3"></v-flex>
        <v-flex md="3"></v-flex>
      </v-layout>
    </v-col>
    <v-col md="12" sm="12">
      <ListingTable :columnCount="6" :dataLoading="searching">
        <template v-slot:thead>
          <thead>
            <tr>
              <th class="simple-table-th">#</th>
              <th class="simple-table-th">Site #</th>
              <th class="simple-table-th">Customer</th>
              <th class="simple-table-th">Contact Person</th>
              <th class="simple-table-th">Site Info</th>
              <th class="simple-table-th">Total</th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody class="custom-border-bottom">
            <template v-if="properties.length">
              <tr
                class="alternate-listing-row"
                v-for="(row, index) in properties"
                :key="index"
              >
                <td class="simple-table-td">{{ index + 1 }}</td>
                <td class="simple-table-td">
                  <Barcode
                    :barcode="row.barcode"
                    route="property.detail"
                    :id="row.id"
                  ></Barcode>
                  <br />
                  <v-chip
                    label
                    color="red"
                    v-if="row.type == 2"
                    class="text-white p-3"
                    small
                    >Billing</v-chip
                  >
                  <v-chip
                    label
                    color="green"
                    class="text-white p-3"
                    v-if="row.type == 1"
                    small
                    >Property</v-chip
                  >
                  <v-chip label color="green" outlined v-if="row.is_tenant"
                    >Tenant</v-chip
                  >
                </td>
                <td class="simple-table-td">
                  <div class="property-listing-customer">
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Display Name: </b>
                      <!--  {{ row.customer.display_name }} -->
                      <span v-if="row.customer.display_name">{{
                        row.customer.display_name
                      }}</span>
                      <em v-else class="text-muted">no Display Name</em>
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Company: </b>
                      <!-- {{ row.customer.company_name }} -->
                      <span v-if="row.customer.company_name">{{
                        row.customer.company_name
                      }}</span>
                      <em v-else class="text-muted">no Company Name</em>
                    </p>
                    <template v-if="!lodash.isEmpty(row.customer)">
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="!lodash.isEmpty(row.customer)"
                      >
                        <b>Email: </b>
                        <span v-if="row.customer.company_email">{{
                          row.customer.company_email
                        }}</span>
                        <em v-else class="text-muted">no email</em>
                        <!--  {{ row.customer.default_person.primary_email }} -->
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-if="!lodash.isEmpty(row.customer)"
                      >
                        <b>Phone: </b>
                        <span v-if="row.customer.company_number">{{
                          row.customer.company_number
                        }}</span>
                        <em v-else class="text-muted">no phone number</em>
                        <!--  {{ row.customer.default_person.primary_phone }} -->
                      </p>
                    </template>
                  </div>
                </td>
                <td class="simple-table-td">
                  <div
                    v-if="row.contact_person"
                    class="property-listing-customer"
                  >
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Name: </b>
                      <span v-if="row.contact_person.full_name">{{
                        row.contact_person.full_name
                      }}</span>
                      <em v-else class="text-muted">no name</em>
                    </p>

                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Display Name: </b>
                      <span v-if="row.contact_person.display_name">{{
                        row.contact_person.display_name
                      }}</span>
                      <em v-else class="text-muted">no display name</em>
                      <!--  {{ row.contact_person.display_name }} -->
                    </p>

                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Email: </b>
                      <span v-if="row.contact_person.primary_email">{{
                        row.contact_person.primary_email
                      }}</span>
                      <em v-else class="text-muted">no email</em>
                      <!-- {{ row.contact_person.primary_email }} -->
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Phone: </b>
                      <span v-if="row.contact_person.primary_phone">{{
                        row.contact_person.primary_phone
                      }}</span>
                      <em v-else class="text-muted">no phone number</em>
                      <!--   {{ row.contact_person.primary_phone }} -->
                    </p>
                  </div>
                </td>
                <td class="simple-table-td">
                  <p class="m-0 custom-nowrap-ellipsis-two-line">
                    <!--  {{ row.property_address }}
                    {{ row.country_name }} -->
                  </p>
                  <div
                    class="property-listing-customer"
                    v-if="lodash.isEmpty(row) === false"
                  >
                    <p class="m-0 custom-nowrap-ellipsis-two-line">
                      <!--  <pre>{{ data }}</pre> -->
                      <b>Site Location Name: </b>
                      <template v-if="row && row.property_name">
                        {{ row.property_name }}
                      </template>
                      <em v-else class="text-muted"> no site location name</em>
                    </p>

                    <p class="m-0 custom-nowrap-ellipsis-two-line">
                      <!--  <pre>{{ row }}</pre> -->
                      <b>Address: </b>
                      {{ row.street_1 }}
                      <template v-if="row.street_2">{{
                        row.street_2
                      }}</template>
                      <br />
                      <template v-if="row.unit_no">{{ row.unit_no }}</template>
                      {{ row.country }} {{ row.zip_code }}
                    </p>
                  </div>
                </td>
                <td class="simple-table-td">
                  <div class="property-listing-customer">
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Quotation: </b>
                      <template v-if="row.quotation_count">
                        <span class="ml-1 count-radius bg-light-success">{{
                          row.quotation_count
                        }}</span>
                      </template>
                      <template v-else
                        ><em class="text--secondary">no quotation</em></template
                      >
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Invoice: </b>
                      <template v-if="row.invoice_count">
                        <span class="ml-1 count-radius bg-light-success">{{
                          row.invoice_count
                        }}</span>
                      </template>
                      <template v-else
                        ><em class="text--secondary">no invoice</em></template
                      >
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Visit: </b>
                      <template v-if="row.visit_count">
                        <span class="ml-1 count-radius bg-light-success">{{
                          row.visit_count
                        }}</span>
                      </template>
                      <template v-else
                        ><em class="text--secondary">no visit</em></template
                      >
                    </p>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else-if="!searching">
              <td :colspan="6" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no address at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </ListingTable>
    </v-col>
  </v-row>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";

export default {
  data() {
    return {
      search: null,
      searching: true,
      properties: [],
    };
  },
  components: {
    Barcode,
    ListingTable,
  },
  props: {
    customer: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    customer() {
      this.getProperties();
    },
  },
  methods: {
    createProperty() {
      this.$router.push(
        this.getDefaultRoute("property.create", {
          query: { customer: this.customer },
        })
      );
    },
    getProperties() {
      if (this.customer) {
        this.searching = true;
        this.$store
          .dispatch(QUERY, {
            url: `property/customer/${this.customer}`,
            data: { search: this.search, status: "all" },
          })
          .then(({ data }) => {
            this.properties = data.rows;
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.searching = false;
          });
      }
    },
  },
  mounted() {
    this.getProperties();
  },
};
</script>
