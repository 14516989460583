<template>
  <v-container fluid class="pb-0">
    <v-layout class="visits-search-bar" v-if="detailType != 'tickets'">
      <v-flex md="2" class="pb-2 mr-2 mxw-200">
        <v-text-field
          v-model.trim="filter_search"
          v-on:click:clear="clearFilter('search')"
          clearable
          dense
          filled
          flat
          label="Search"
          hide-details
          solo
          :loading="pageLoading"
          :disabled="pageLoading"
          color="cyan"
          @keydown.enter="getVisits()"
          @keydown.tab="getVisits()"
          v-on:change="getVisits()"
        ></v-text-field>
      </v-flex>
      <v-flex md="2" class="pb-2 mr-2 mxw-200">
        <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              filled
              flat
              label="Visit Date Range"
              hide-details
              solo
              :loading="pageLoading"
              :disabled="pageLoading"
              clearable
              prepend-inner-icon="mdi-calendar"
              v-on:click:clear="clearFilter('dates')"
              readonly
              v-bind="attrs"
              v-on="on"
              :value="formattedDate"
              color="cyan"
            ></v-text-field>
          </template>
          <v-date-picker
            color="cyan"
            range
            :disabled="pageLoading"
            v-on:change="getVisits"
            v-model="dates"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex
        md="2"
        class="pb-2 mx-2 mx-width-400"
        v-if="detailRoute != 'follow-up.detail'"
      >
        <v-autocomplete
          hide-details
          v-model.trim="filter_status"
          :items="statusList"
          dense
          flat
          filled
          label="Visit Status"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading"
          :loading="pageLoading"
          v-on:change="getVisits"
          item-value="status"
          item-text="text"
          class="filter-select"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Status(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-action class="ma-0">
              <v-chip
                style="height: 10px; width: 10px; padding: 0"
                :color="item.color"
              >
              </v-chip>
            </v-list-item-action>
            <v-list-item-content class="py-0">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
              >
                <template v-if="item.value == 'all'"
                  >{{ item.text }} ({{ item.all_ticket_visits }})</template
                >
                <template v-else
                  >{{ item.text }} ({{
                    item.ticket_visit_status_count
                  }})</template
                >
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-action class="mr-0">
              <v-chip
                style="height: 16px; width: 16px; padding: 0"
                :color="item.color"
              >
              </v-chip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action class="align-self-center">
              <v-list-item-subtitle
                class="text-lowercase font-weight-500 font-size-14"
              >
                <template v-if="item.value == 'all'">{{
                  item.all_ticket_visits
                }}</template>
                <template v-else>{{
                  item.ticket_visit_status_count
                }}</template></v-list-item-subtitle
              >
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex v-if="engineerId" md="2" class="pb-2 mr-2 mxw-200">
        <v-autocomplete
          hide-details
          v-if="!internal"
          v-model.trim="filter_customer"
          clearable
          :items="customerList"
          dense
          flat
          filled
          label="Customer"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading || customerLoading"
          :loading="customerLoading || pageLoading"
          item-text="display_name"
          item-value="id"
          v-on:change="
            getPropertyList();
            getVisits();
          "
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Customer(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="$assetAPIURL(item.profile_logo)"
                aspect-ratio="1"
                class="margin-auto"
                transition="fade-transition"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.display_name }}</v-list-item-title
              >
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.company_name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action
              v-if="item.default_person"
              class="align-self-center"
            >
              <v-list-item-subtitle
                class="text-lowercase pb-2 font-weight-500 font-size-14"
                >{{ item.default_person.primary_email }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                class="text-lowercase font-weight-500 font-size-14"
                >{{ item.default_person.primary_phone }}</v-list-item-subtitle
              >
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex md="2" class="pb-2 mr-2 mxw-200">
        <v-autocomplete
          hide-details
          v-if="!internal"
          clearable
          v-model.trim="filter_property"
          :items="propertyList"
          dense
          flat
          filled
          label="Property"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading || propertyLoading"
          :loading="propertyLoading || pageLoading"
          item-text="barcode"
          item-value="id"
          v-on:change="getVisits"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Property(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-home-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-ellipsis max-width-400px">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.barcode }} ({{ getFormattedProperty(item) }})
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex md="2" class="pb-2 mr-2 mxw-200">
        <v-autocomplete
          hide-details
          v-if="!internal"
          clearable
          v-model.trim="filter_engineer"
          :items="engineerList"
          dense
          flat
          filled
          label="Engineer"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading || engineerLoading"
          :loading="engineerLoading || pageLoading"
          item-text="display_name"
          item-value="id"
          v-on:change="getVisits"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Engineer(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar
              height="30"
              min-width="30"
              width="30"
              class="mr-1 my-1 grey lighten-2 grey--text"
            >
              <v-img
                v-if="item.profile_logo != null"
                src="item.profile_logo"
              ></v-img>
              <span v-else class="fw-600 mb-1">{{
                item.display_name.slice(0, 2).toUpperCase()
              }}</span>
            </v-list-item-avatar>
            <v-list-item-content class="text-ellipsis max-width-400px">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.display_name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action
              class="align-self-center"
              v-if="filter_engineer == item.id"
            >
              <v-icon color="cyan">mdi-check</v-icon>
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-flex>
      <div class="pr-0 pb-2 d-flex">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              tile
              depressed
              :disabled="pageLoading"
              v-on:click="getVisits"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
        <v-tooltip
          v-if="!internal && detailRoute != 'follow-up.detail'"
          top
          content-class="custom-top-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 custom-bold-button custom-btn-color white--text"
              tile
              depressed
              :disabled="pageLoading"
              v-on:click="createJob()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Create Visit</span>
        </v-tooltip>
        <!-- {{ $route.query.tab }} -->

        <v-btn
          v-if="detailRoute != 'follow-up.detail'"
          :disabled="pageLoading"
          :type-id="$route.query.tab"
          class="mx-2 custom-bold-button"
          v-on:click="exportQuotation"
          color="cyan white--text"
        >
          <v-icon dark left>mdi-database-export</v-icon> Export
        </v-btn>
      </div>
      <v-flex v-if="!internal" md="2" class="pb-2 ml-2">
        <v-menu offset-y left>
          <!-- <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  depressed
                  :disabled="pageLoading"
                  class="custom-bold-button"
                  color="cyan white--text"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  {{ entityObject.name }}
                </v-btn>
              </template>
              <span>Entity</span>
            </v-tooltip>
          </template> -->
          <v-list>
            <v-list-item
              v-for="(item, index) in entityList"
              :key="index"
              link
              v-on:click="updateEntity(item)"
            >
              <v-list-item-title class="font-weight-600">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-row class="custom-listing-table">
      <v-col md="12" class="col-12 visit-simple-table pt-0 pb-0">
        <v-simple-table
          class="inner-simple-table custom-border-top simple-table custom-table-height"
          fixed-header
        >
          <template v-slot:default>
            <tbody>
              <template v-if="lodash.isEmpty(rowData) === false">
                <template v-for="(row, index) in rowData">
                  <template v-if="row.header">
                    <tr
                      v-if="!internal"
                      :key="index"
                      :class="{ 'custom-border-top': index > 0 }"
                    >
                      <td
                        colspan="6"
                        class="font-size-16 text-uppercase py-1 font-weight-700 custom-border-bottom visit-header-hover"
                        :class="[row.header_text]"
                        :style="{ color: row.header_color }"
                      >
                        {{ row.header_text }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <v-hover v-slot="{ hover }" :key="index">
                      <tr
                        link
                        class="visit-listing-row"
                        :key="'visit-row' + index"
                        :class="{
                          'visit-row-hover': hover,
                          'custom-border-bottom': rowData[index + 1],
                          'row-loading': isRowLoading[index],
                        }"
                        v-on:click="
                          $router.push(
                            getDefaultRoute(detailRoute, {
                              params: { id: row.visit_id },
                            })
                          )
                        "
                      >
                        <td
                          width=""
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                          :style="{ color: row.header_color }"
                        >
                          <template v-if="row.visit_barcode">
                            <div class="cursor-pointer">
                              <Barcode
                                dense
                                small
                                :barcode="row.visit_barcode"
                              ></Barcode>
                            </div>
                            <!-- <div class="mt-2 mb-1 cursor-pointer">
                              <Barcode
                                dense
                                small
                                :barcode="row.ticket_barcode"
                              ></Barcode>
                            </div> -->
                            <div class="my-0">
                              <v-chip
                                small
                                class="font-weight-600 custom-blue-border text-uppercase justify-center my-0 font-10"
                                color="chip-custom-blue"
                                label
                                outlined
                              >
                                {{ row.ticket_job_type_text }}
                              </v-chip>
                            </div>
                            <div class="my-0">
                              <v-chip
                                small
                                v-if="row.quotation_barcode"
                                class="text-white p-3 cursor-pointer"
                                color="cyan"
                                label
                                outlined
                                v-on:click.prevent.stop="
                                  routeToQuotation(row.quotation_id)
                                "
                              >
                                {{ row.quotation_barcode }}
                              </v-chip>
                            </div>
                          </template>
                          <template v-else> - </template>
                        </td>
                        <td
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                          style="min-width: 340px"
                        >
                          <div class="job-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis-two-line">
                              <b>Job Title: </b>{{ row.visit_title }}
                            </p>

                            <p
                              class="m-0 custom-nowrap-ellipsis-two-line"
                              v-if="row.property_address"
                            >
                              <span
                                content="Service Location"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 18px" color="#000">
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                {{ row.property_address }}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td
                          width=""
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                        >
                          <div class="job-listing-customer">
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Contact Person"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-account
                                  </v-icon>
                                </b>
                                {{
                                  row.ticket_attention
                                    ? row.ticket_attention
                                    : row.customer_display_name
                                }}
                              </span>
                              <v-chip
                                label
                                small
                                color="green white--text"
                                class="d-inline mx-2"
                                v-if="row.is_property_tenant"
                                >Tenant</v-chip
                              >
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Company Name"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-bag-checked
                                  </v-icon>
                                </b>
                                {{ row.customer_company_name }}
                              </span>
                            </p>

                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Property Contact Person Email"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-email
                                  </v-icon>
                                </b>
                                {{ row.property_primary_email }}
                              </span>
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Property Contact Person Phone"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-phone
                                  </v-icon>
                                </b>
                                {{ row.property_primary_phone }}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td
                          width="300px"
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                          :style="{ color: row.header_color }"
                        >
                          <p class="m-0 max-content-width text-truncate">
                            <span class="d-block">
                              {{ formatDate(row.visit_started_at) }}
                              {{ formatDateTimeStart(row.visit_started_at) }}
                              <br />
                              {{ formatDate(row.visit_finished_at) }}
                              {{ formatDateTimeStart(row.visit_finished_at) }}
                            </span>
                            <span class="d-block">
                              <v-chip
                                class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                                color="chip-custom-blue"
                                small
                                outlined
                                label
                              >
                                <template v-if="row.visit_status === 4">
                                  completed in {{ row.visit_duration_human }}
                                </template>
                                <template v-else-if="row.visit_status === 2">
                                  in-progress
                                </template>
                                <template v-else>
                                  <template v-if="row.visit_overdue === 1"
                                    >delayed by
                                    {{
                                      calculateTotalDuration(
                                        row.visit_started_at
                                      )
                                    }}</template
                                  >
                                  <template v-else-if="row.visit_today === 1"
                                    >ends in
                                    {{
                                      calculateTotalDuration(
                                        row.visit_finished_at
                                      )
                                    }}</template
                                  >
                                  <template v-else
                                    >starts in
                                    {{
                                      calculateTotalDuration(
                                        row.visit_started_at
                                      )
                                    }}</template
                                  >
                                </template>
                              </v-chip>
                            </span>
                          </p>
                        </td>
                        <td
                          width="140px"
                          class="font-size-14 font-weight-500 td-chip-small"
                        >
                          <CustomStatus
                            small
                            :status="statusIntiger(row.visit_status)"
                            endpoint="visit/status"
                          ></CustomStatus>
                          <Priority
                            small
                            :priority="priortyInteger(row.ticket_priority)"
                          ></Priority>
                          <!--  <span v-if="row.follow_up_status == 1" class="mx-2 d-inline justify-center font-10"> 
                            Yes
                          </span> -->
                          <template v-if="row.follow_ups_data == 1">
                            <div class="my-1">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    outlined
                                    label
                                    class="mx-2 d-inline justify-center font-10"
                                    :color="'blue white--text'"
                                  >
                                    Follow Up Open
                                  </v-chip>
                                </template>
                                <span>Follow Up Open</span>
                              </v-tooltip>
                            </div>
                          </template>
                          <template v-if="row.follow_ups_data == 2">
                            <div class="my-1">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    outlined
                                    label
                                    class="mx-2 d-inline justify-center font-10"
                                    :color="'red white--text'"
                                  >
                                    Follow Up Closed
                                  </v-chip>
                                </template>
                                <span>Follow Up Closed</span>
                              </v-tooltip>
                            </div>
                          </template>
                        </td>
                        <td width="240px" class="font-size-14 font-weight-500">
                          <div class="job-listing-customer">
                            <p
                              v-if="false"
                              class="m-0 max-content-width text-truncate font-weight-700"
                              :class="{
                                'text--secondary': lodash.isEmpty(
                                  row.visit_engineers
                                ),
                              }"
                            >
                              {{ engineerDisplayText(row.visit_engineers) }}
                            </p>
                            <template
                              v-if="
                                !lodash.isEmpty(row.visit_engineers) && false
                              "
                            >
                              <template
                                v-for="(engineer, index) in row.visit_engineers"
                              >
                                <template v-if="index < 3">
                                  <v-tooltip
                                    top
                                    content-class="custom-top-tooltip"
                                    :key="'engineer-tooltip' + index"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-avatar
                                        v-on="on"
                                        v-bind="attrs"
                                        width="35"
                                        height="35"
                                        min-width="35"
                                        class="mr-1"
                                        color="grey lighten-2 grey--text"
                                        style="
                                          margin-left: -12px;
                                          border: 2px solid #fff !important;
                                        "
                                        :key="index"
                                      >
                                        <template
                                          v-if="engineer.profile_logo != null"
                                        >
                                          <v-img
                                            :src="
                                              engineer.profile_logo + '?fafa'
                                            "
                                          ></v-img>
                                        </template>
                                        <template v-else>
                                          <span class="fs-15">{{
                                            engineer.display_name
                                              .slice(0, 2)
                                              .toUpperCase()
                                          }}</span>
                                        </template>
                                      </v-avatar>
                                    </template>
                                    <span>{{ engineer.display_name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-else>
                                  +{{ row.visit_engineers.length - 3 }} more...
                                </template>
                              </template>
                            </template>

                            <template v-if="row.visit_team_name">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <!-- <v-avatar
                                    v-on="on"
                                    v-bind="attrs"
                                    width="35"
                                    height="35"
                                    min-width="35"
                                    class="mr-1"
                                    color="grey lighten-2 grey--text"
                                    style="
                                      margin-left: -12px;
                                      border: 2px solid #fff !important;
                                    "
                                  >
                                    <template>
                                      <span class="fs-15">{{
                                        row.visit_team_name
                                          
                                      }}</span>
                                    </template>
                                  </v-avatar> -->

                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    label
                                    class="mx-2 d-inline justify-center font-10"
                                    :color="'blue white--text'"
                                  >
                                    {{ row.visit_team_name }}
                                  </v-chip>
                                </template>
                                <span>{{ row.visit_team_name }}</span>
                              </v-tooltip>
                            </template>

                            <template v-else>
                              <p
                                class="m-0 max-content-width text-truncate font-weight-700 text--secondary"
                              >
                                Not assigned yet.
                              </p>
                            </template>

                            <!--  <template>
                              <p
                                class="m-0 max-content-width text-truncate font-weight-700 text--secondary"
                              >
                                <template>
                                  {{
                                    checkStartTimeButton(
                                      row.ticket_visit_teams_data
                                    )
                                  }}
                                </template>
                              </p>
                            </template> -->
                          </div>
                          <template>
                            <v-btn
                              depressed
                              color="red lighten-1 white--text visit-delete-btn my-auto mt-2"
                              v-on:click.stop="deleteVisit(row)"
                            >
                              <v-icon style="font-size: 18px; line-height: 18px"
                                >mdi-delete</v-icon
                              >
                              Delete
                            </v-btn>
                          </template>
                        </td>
                      </tr>
                    </v-hover>
                  </template>
                </template>
              </template>
              <template v-else-if="pageLoading">
                <template v-for="(row, index) in 3">
                  <tr :key="'ref-0-' + index">
                    <td colspan="6">
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text width-350px"
                      ></v-skeleton-loader>
                    </td>
                  </tr>
                  <tr :key="'ref-1-' + index">
                    <td width="150">
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text"
                      ></v-skeleton-loader>
                    </td>
                    <td width="400">
                      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                    </td>
                    <td width="300">
                      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                    </td>
                    <td width="200" class="py-4">
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text"
                      ></v-skeleton-loader>
                    </td>
                    <td>
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                    </td>
                    <td>
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-else>
                <td colspan="5" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no visit at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12" class="col-12 pb-0 pt-0">
        <ListingFooter
          :dataLoading="pageLoading"
          :currentPage="currentPage"
          :totalPages="totalPages"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
        ></ListingFooter>
      </v-col>
    </v-row>
    <DeleteDialog :delete-dialog="deleteVisitDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="deleteVisitLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteVisitDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="deleteVisitLoading"
          :loading="deleteVisitLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteVisitConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <template v-if="entityDialog">
      <EntityDialog
        :entity-dialog="entityDialog"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectEntity="selectEntity"
      ></EntityDialog>
    </template>
    <template v-if="customerDialog">
      <CustomerDialog
        :entity="entity"
        :customerDialog="customerDialog"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomer="selectCustomer"
      ></CustomerDialog>
    </template>
    <template v-if="customerPersonDialog">
      <CustomerPersonDialog
        disabled-auto-select
        :customerPersonDialog="customerPersonDialog"
        :customer="customer"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomerPerson="selectCustomerPerson"
      ></CustomerPersonDialog>
    </template>
    <template v-if="customerPropertyDialog">
      <CustomerPropertyDialog
        disabled-auto-select
        :customerPropertyDialog="customerPropertyDialog"
        :customer="customer"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomerProperty="selectCustomerProperty"
      ></CustomerPropertyDialog>
    </template>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { QUERY, DELETE } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { getConfig, saveData } from "@/core/services/local.service";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import Priority from "@/view/pages/partials/Priority.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import { find, isEmpty, toSafeInteger } from "lodash";
import JwtService from "@/core/services/jwt.service";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "visit-listing-template",
  mixins: [CommonMixin],
  props: {
    visitType: {
      type: String,
      required: true,
      default: "all",
    },
    detailRoute: {
      type: String,
      default: null,
    },
    detailType: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    internal: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    engineerId: {
      type: Number,
      default: 0,
    },
    teamId: {
      type: Number,
      default: 0,
    },
    contractId: {
      type: Number,
      default: 0,
    },
    quotationId: {
      type: Number,
      default: 0,
    },
    invoiceId: {
      type: Number,
      default: 0,
    },
    entityId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    entityId() {
      this.initInternal();
    },
    customerId() {
      this.initInternal();
    },
    engineerId() {
      this.initInternal();
    },
    teamId() {
      this.initInternal();
    },
    quotationId() {
      this.initInternal();
    },
    invoiceId() {
      this.initInternal();
    },
  },
  components: {
    DeleteDialog,
    Barcode,
    Priority,
    CustomStatus,
    ListingFooter,
    EntityDialog,
    CustomerDialog,
    CustomerPersonDialog,
    CustomerPropertyDialog,
  },
  data() {
    return {
      totalRows: 0,
      rowsOffset: 0,
      currentPage: 1,
      totalPages: 0,

      perPage: 15,
      pageLoading: false,
      customerLoading: false,
      propertyLoading: false,
      customerDialog: false,
      entityDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      engineerLoading: false,
      isRowLoading: {},
      dates: [],
      statusList: [],
      customerList: [],
      propertyList: [],
      engineerList: [],
      rowData: [],
      filter_search: null,
      filter_customer: null,
      filter_engineer: null,
      filter_quotation: null,
      filter_contract: null,
      filter_invoice: null,
      filter_status: null,
      filter_team: null,
      filter_property: null,
      timeout: null,
      timeoutLimit: 500,
      entity: 0,
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      entityList: [],
      entityObject: {},
      deleteVisitId: null,
      deleteVisitDialog: false,
      deleteVisitLoading: false,
      deleteVisitBarcode: null,
    };
  },
  mounted() {
    const _this = this;
    console.log(this.$route.query.tab);

    _this.initInternal();

    _this.entityList = getConfig("entity");

    _this.entityObject = _this.lodash.head(_this.entityList);

    _this.$nextTick(() => {
      if (!_this.internal || _this.engineerId) {
        _this.getVisits();
        _this.getCustomerList();
        _this.getEngineerList();
      }

      _this
        .getStatusList()
        .then((status) => {
          _this.statusList = status;
        })
        .catch((error) => {
          _this.logError(error);
        });
    });
  },
  methods: {
    statusIntiger(param) {
      return toSafeInteger(param);
    },
    priortyInteger(param) {
      return toSafeInteger(param);
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      //console.log(row,"row");
      return moment(row).format("hh:mm A");
    },
    routeToQuotation(id) {
      this.$router.push({
        name: "admin.quotation.detail",
        params: { id },
      });
    },
    deleteVisit({ visit_id, visit_barcode }) {
      this.deleteVisitId = visit_id;
      this.deleteVisitDialog = true;
      this.deleteVisitBarcode = visit_barcode;
    },
    exportQuotation() {
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "visits/export";
      let tab = this.$route.query.tab;
      console.log(tab);
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      downloadURL.searchParams.append("search", tab);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    deleteVisitConfirm() {
      this.deleteVisitLoading = true;
      this.$store
        .dispatch(DELETE, { url: `visit/${this.deleteVisitId}` })
        .then(() => {
          this.deleteVisitId = null;
          this.deleteVisitDialog = false;
          this.deleteVisitBarcode = null;
          this.getVisits();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteVisitLoading = false;
        });
    },
    createJob() {
      /* if (!this.entity) {
        this.entityDialog = true;
      } else */ if (!this.customer) {
        this.customerDialog = true;
      } else if (!this.customerProperty) {
        this.customerPropertyDialog = true;
      }
    },
    initInternal() {
      this.entity = this.lodash.toSafeInteger(this.entityId);
      this.customer = this.lodash.toSafeInteger(this.customerId);
      this.filter_quotation = this.lodash.toSafeInteger(this.quotationId);
      this.filter_contract = this.lodash.toSafeInteger(this.contractId);
      this.filter_invoice = this.lodash.toSafeInteger(this.invoiceId);
      this.filter_engineer = this.lodash.toSafeInteger(this.engineerId);
      this.filter_team = this.lodash.toSafeInteger(this.teamId);
      this.filter_customer = this.customer;
      if (this.filter_team) {
        this.$nextTick(() => {
          this.getVisits();
          this.getStatusList();
        });
      }
      if (this.filter_engineer) {
        this.$nextTick(() => {
          this.getVisits();
          this.getStatusList();
        });
      }
      if (this.entity && this.customer && this.filter_customer) {
        this.$nextTick(() => {
          this.getVisits();
          this.getStatusList();
          this.getPropertyList();
        });
      }
    },
    updateEntity(row) {
      this.entityObject = row;
      this.getVisits();
      this.getCustomerList();
      this.propertyList = [];
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 0;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectProject(param) {
      this.projectId = param;
    },
    selectContact(param) {
      this.contractId = param;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkJob();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkJob();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkJob();
    },
    checkJob() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("job.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    calculateTotalDuration(visit_date) {
      if (moment(visit_date).isValid()) {
        let a = moment(visit_date);
        let b = moment();

        let totalDuration = moment.duration(a.diff(b));
        if (b.isAfter(a)) {
          totalDuration = moment.duration(b.diff(a));
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        return result.join(" ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter_search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getVisits();
    },
    getVisits() {
      const _this = this;

      if (_this.pageLoading) {
        return false;
      }

      let filter = {
        daterange: _this.dates,
        entity: _this.entityObject ? _this.entityObject.id : undefined,
        search: _this.lodash.toString(_this.filter_search) || null,
        invoice: _this.lodash.toSafeInteger(_this.filter_invoice) || null,
        quotation: _this.lodash.toSafeInteger(_this.filter_quotation) || null,
        contract: _this.lodash.toSafeInteger(_this.filter_contract) || null,
        customer: _this.lodash.toSafeInteger(_this.filter_customer) || null,
        engineer: _this.lodash.toSafeInteger(_this.filter_engineer) || null,
        status: _this.lodash.toSafeInteger(_this.filter_status) || null,
        property: _this.lodash.toSafeInteger(_this.filter_property) || null,
        team: _this.lodash.toSafeInteger(_this.filter_team) || null,
        type: _this.visitType,
      };

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }

      _this.rowData = [];

      _this.pageLoading = true;

      let current_page = _this.currentPage;
      let per_page = _this.perPage;

      _this
        .getVisitList({ filter, current_page, per_page })
        .then((data) => {
          _this.rowData = data.rows;

          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    checkStartTimeButton(rowData) {
      //console.log(rowData, "rowData");
      if (!isEmpty(rowData)) {
        let VisitAssignData = find(rowData, (row) => row.engineer_active == 1);

        if (VisitAssignData) {
          return "";
        } else {
          return "Unassigned";
        }
      }
    },

    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    getVisitList(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "visit",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      return tempArr.join(", ");
    },
    getEngineers() {
      const _this = this;
      _this.engineerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "engineer-list",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              _this.engineerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.engineerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.engineerLoading = false;
          reject(error);
        }
      });
    },
    getCustomers() {
      const _this = this;
      _this.customerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "customer-list",
              data: { entity: _this.entityObject.id, all: true },
            })
            .then(({ data }) => {
              saveData("_btacl_tmp", data);
              resolve(data);
            })
            .catch((error) => {
              _this.customerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.customerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.customerLoading = false;
          reject(error);
        }
      });
    },
    getCustomerProperties(customer) {
      const _this = this;
      _this.propertyLoading = true;
      _this.filter_property = null;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "property-list/" + (customer || ""),
              data: { entity: _this.entityObject.id, all: true },
            })
            .then(({ data }) => {
              saveData("_btacpl_" + customer + "_tmp", data);
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.propertyLoading = false;
            });
        } catch (error) {
          _this.propertyLoading = false;
          reject(error);
        }
      });
    },
    getEngineerList() {
      const _this = this;

      _this
        .getEngineers()
        .then((engineer) => {
          _this.engineerList = engineer;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerList() {
      const _this = this;

      if (_this.customerLoading) {
        return false;
      }

      _this
        .getCustomers()
        .then((customer) => {
          _this.customerList = customer;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getPropertyList();
        });
    },
    getPropertyList(param) {
      const _this = this;

      if (_this.propertyLoading) {
        return false;
      }

      _this.propertyList = [];
      _this
        .getCustomerProperties(param)
        .then((status) => {
          _this.propertyList = status;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getStatusList() {
      const _this = this;

      let filter = {
        daterange: _this.dates,
        entity: _this.entityObject ? _this.entityObject.id : undefined,
        search: _this.lodash.toString(_this.filter_search) || null,
        invoice: _this.lodash.toSafeInteger(_this.filter_invoice) || null,
        quotation: _this.lodash.toSafeInteger(_this.filter_quotation) || null,
        customer: _this.lodash.toSafeInteger(_this.filter_customer) || null,
        engineer: _this.lodash.toSafeInteger(_this.filter_engineer) || null,
        status: _this.lodash.toSafeInteger(_this.filter_status) || null,
        property: _this.lodash.toSafeInteger(_this.filter_property) || null,
        type: _this.visitType,
      };

      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, { url: "visit/status", data: filter })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      let rowData = this.lodash.map(this.rowData, function (row) {
        if (!row.header) return row;
      });

      rowData = this.lodash.without(rowData, undefined);

      return this.rowsOffset + (rowData ? rowData.length : 0);
    },
  },
  beforeDestroy() {
    /*PaginationEventBus.$off("update:pagination");
    PaginationEventBus.$off("update:per-page");*/
  },
  created() {
    const _this = this;

    const statusText = _this.lodash.toLower(_this.$route.query.status) || "all";

    if (statusText) {
      switch (statusText) {
        case "all":
          _this.filter_status = 0;
          break;
        case "open":
          _this.filter_status = 1;
          break;
        case "cancel":
          _this.filter_status = 5;
          break;
      }
    }

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getVisits();
    });

    PaginationEventBus.$on("update:per-page", (param) => {
      _this.perPage = param;
    });
  },
};
</script>
